import { AccountReferral } from "@kinherit/sdk";

// export const RecordAccountReferralHandler = async (message: {
//   accountReferral: string;
// }) => {
//   const request = Api.resource(
//     "partner-portal",
//     "/v2/partner-portal/account-referral/{accountReferral}",
//     message,
//   ).method("get");

//   const response = await request.result();

//   return {
//     profile: Profile.$inflate(response.profile),
//     lead: Lead.$inflate(response.lead),
//     kintin: Kintin.$inflate(response.kintin),
//     accountReferral: AccountReferral.$inflate(response.accountReferral),
//     accountReferralCode: AccountReferralCode.$inflate(
//       response.accountReferralCode,
//     ),
//     emailAddress: EmailAddress.$inflate(response.emailAddress),
//     phoneNumber: PhoneNumber.$inflate(response.phoneNumber),
//     user: User.$inflate(response.user),
//   };
// };

import { DefineReadAction } from "@kinherit/framework/action-bus/actions/read";

export const RecordAccountReferralHandler = DefineReadAction({
  interface: "partner-portal",
  resource: "/v2/partner-portal/account-referral/{accountReferral}",
  method: "get",
  rootModel: AccountReferral,
  parse: (message: { accountReferral: string }) => ({
    params: {
      accountReferral: message.accountReferral,
    },
  }),
});
