import { CreateAccountReferralHandler } from "@/module/clients/action/account-referral/create-account-referral.action";
import { CreateKinvaultAuthTokenHandler } from "@/module/clients/action/account-referral/create-kinvault-auth-token.action";
import { ReadAccountReferralOrdersHandler } from "@/module/clients/action/account-referral/read-account-referral-orders.action";
import { ReadAccountReferralHandler } from "@/module/clients/action/account-referral/read-account-referral.action";
import { RecordAccountReferralHandler } from "@/module/clients/action/account-referral/record-account-referral.action";
import { DefineActionBus } from "@kinherit/framework/action-bus/bus";

// export const Actions = {
//   // clients
//   "partner-portal/clients/read": ReadAccountReferralHandler,
//   "partner-portal/clients/record": RecordAccountReferralHandler,
//   "partner-portal/clients/create": CreateAccountReferralHandler,
//   "partner-portal/clients/order/read": ReadAccountReferralOrdersHandler,
// };

export const ClientsActionBus = DefineActionBus({
  name: "Clients",
  actions: {
    CreateKinvaultAuthToken: CreateKinvaultAuthTokenHandler,
    read: ReadAccountReferralHandler,
    record: RecordAccountReferralHandler,
    create: CreateAccountReferralHandler,
    order: DefineActionBus({
      name: "Clients.Order",
      actions: {
        read: ReadAccountReferralOrdersHandler,
      },
    }),
  },
});
