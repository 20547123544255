<template>
  <PageLayout
    class="partner-portal-account-referral-master-list"
    title="Your Clients"
    strap="Check where your help is needed to move things along and boost your conversion"
  >
    <MasterListPage
      v-if="$data.filters"
      :filters="$data.filters"
      :columns="columns"
      :rows="rows"
      v-model:current-page="pagination.currentPage"
      v-model:per-page="pagination.perPage"
      :count="pagination.count"
      :last-page="pagination.lastPage"
      @refresh="refresh"
      @row-clicked="handleClick"
      is-plain
      :sort-by-options="{
        createdAt: 'Created',
      }"
      v-model:sort-by="sort.by"
      v-model:sort-direction="sort.direction"
      :persist-state="true"
    >
      <template #iconStatus="{ row }">
        <Icon
          v-if="row.kintin"
          :icon="
            row.kintin?.type?.value === 'single'
              ? ThemeIconName.Person
              : ThemeIconName.PersonFriend
          "
        />
      </template>
      <template #partnerPortalGroup="{ row }">
        <Badge
          is-small
          is-rounded
          :text="calculateAccountReferralStatus(row).text"
          :class="'is-block'"
          :color="calculateAccountReferralStatus(row).color"
        />
      </template>
      <template #clientName="{ row }: { row: AccountReferral }">
        <strong
          >{{ row.kintin?.friendlyName ?? row.lead?.profile?.fullName }}
        </strong>
        <small class="is-block">
          {{ row.kintin?.ref ?? "Prospect" }}

          <span class="is-block">
            Kinvault
            <span v-if="['advised'].includes(row.kintin?.process?.value!)">
              &amp; Estate Planning
            </span>
            <span
              v-else-if="['lpasonly'].includes(row.kintin?.process?.value!)"
            >
              &amp; LPAs
            </span>
            <span v-if="row.lead?.referral.externalId">
              | {{ row.lead?.referral.externalId }}</span
            >
          </span>
        </small>
      </template>
    </MasterListPage>
  </PageLayout>
</template>

<script lang="ts">
import { ReadAccountReferralForm } from "@/module/clients/form/read-account-referral.form";
import { ParterPortalMixin } from "@/module/dashboard/mixin/partner-portal.mixin";
import { AuthService } from "@/service/auth.service";
import PageLayout from "@/shared/component/PageLayout.vue";
import { Badge } from "@kinherit/framework/component.display/badge";
import Icon from "@kinherit/framework/component.display/icon";
import { TableColumn } from "@kinherit/framework/component.display/table/types";
import { MasterListPage } from "@kinherit/framework/component.page/master-list-page";
import { ThemeIconName } from "@kinherit/framework/theme/prop/icon";
import { AccountReferral, IAccountReferral } from "@kinherit/sdk";
import { defineComponent } from "vue";
import {
  PartnerPortalAccountReferralDetailsParams,
  PartnerPortalAccountReferralDetailsRoute,
  PartnerPortalAccountReferralMasterListRoute,
} from ".";
export default defineComponent({
  name: PartnerPortalAccountReferralMasterListRoute,
  mixins: [
    AuthService.mixin({
      module: "partner-portal",
    }),
    ParterPortalMixin,
  ],
  components: { MasterListPage, Icon, PageLayout, Badge },
  data: () => ({
    ThemeIconName,
    filters: ReadAccountReferralForm(),
    columns: [
      {
        title: "Referral Date",
        sort: true,
        map: (v) => v.createdAt.formatDate,
      },
      {
        title: "",
        sort: false,
        slot: "iconStatus",
      },
      {
        title: "Client",
        sort: true,
        slot: "clientName",
      },
      {
        title: "Referred By",
        sort: true,
        map: (v) => v.contact?.profile.fullName ?? "Unassigned",
      },
      {
        title: "Status",
        slot: "partnerPortalGroup",
      },
    ] as Array<TableColumn<AccountReferral>>,
    rows: Array<AccountReferral>(),
    pagination: {
      currentPage: 1,
      lastPage: 0,
      perPage: 15,
      count: 0,
    },
    sort: {
      by: "createdAt" as keyof IAccountReferral,
      direction: "desc" as "desc" | "asc",
    },
  }),
  methods: {
    handleClick(accountReferral: AccountReferral, event: MouseEvent): void {
      window.Kernel.visitRoute(
        {
          name: PartnerPortalAccountReferralDetailsRoute,
          params: {
            accountReferral: accountReferral.id,
          } as PartnerPortalAccountReferralDetailsParams,
        },
        event.ctrlKey,
      );
    },
    async refresh(
      formData: ReturnType<typeof ReadAccountReferralForm>["localData"],
    ): Promise<void> {
      // const data = await window.Kernel.ActionBus.execute(
      //   "partner-portal/clients/read",
      //   {
      //     ...formData,
      //     sort: this.sort,
      //     pagination: this.pagination,
      //   },
      // );
      const data = await window.Kernel.ActionBus.clients.read({
        message: formData,
        sort: this.sort,
        pagination: this.pagination,
      });

      this.rows = data.accountReferral;
      this.pagination.currentPage = data.$pagination.currentPage;
      this.pagination.lastPage = data.$pagination.lastPage;
      this.pagination.count = data.$pagination.count;
      this.$forceUpdate();
    },
  },
});
</script>

<cypress-wrapper lang="json">
{
  "name": "AccountReferralMasterListPageWrapper",
  "selector": ".partner-portal-account-referral-master-list",
  "route": "PartnerPortalAccountReferralMasterList",
  "imports": {
    "ReadAccountReferralFormWrapper": "@/module/clients/form/read-account-referral.form.test"
  },
  "extends": {
    "name": "MasterListPageWrapper",
    "path": "@kinherit/framework/component.page/master-list-page/master-list-page.test"
  },
  "methods": {
    "entries": {
      "type": "to-many",
      "selector": "tbody",
      "wrapper": "AccountReferralMasterListRow"
    },
    "filter": {
      "type": "to-one",
      "selector": "",
      "wrapper": "ReadAccountReferralFormWrapper"
    }
  }
}
</cypress-wrapper>

<cypress-wrapper lang="json">
{
  "name": "AccountReferralMasterListRow",
  "methods": {}
}
</cypress-wrapper>
