import { Api } from "@kinherit/sdk/api";

export const KinvaultUrlHandler = async (message: {}) => {
  const request = Api.resource(
    "core",
    "/v2/core/environment/kinvault-url",
    message,
  ).method("get");

  return await request.result();
};
